<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import InputGroup from "@/components/forms/input-group";
import SaveButton from "@/components/buttons/save-button";
import axios from 'axios';
import { authHeader } from '@/helpers/auth-header';

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SaveButton,
    },
    data() {
        return {
            id: '',
            title: `profile`,
            items: [
                {
                    text: "Profile"
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
            name: '',
            email: '',
            pwd: '',
            cnfPwd: '',
            errors: {
            },
            status: '',
            userType: '',
        };
    },
    methods: {
        async chgPassword() {
            this.errors = {};
            let password = this.pwd;
            let confirm_password = this.cnfPwd;
            if (password == '') {
                this.errors.pwd = ['Field Cannot be Empty'];
            } else if (password.length < 8) {
                this.errors.pwd = ['Password must be more than 8 letters'];
            }

            if (confirm_password == '') {
                this.errors.cnfPwd = ['Field Cannot be Empty'];
            } else if (password.length < 8) {
                this.errors.cnfPwd = ['Password must be more than 8 letters'];
            }



            if (!Object.hasOwn(this.errors,'cnfPwd') && !Object.hasOwn(this.errors, 'pwd')) {
                if (password === confirm_password) {
                    const result = await axios.put(`${process.env.VUE_APP_APIURL}/admin/profile/password`, {
                        "password": password,
                        "password_confirmation": confirm_password
                    },{
                        headers: authHeader(),
                    });

                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('The password has been updated successfully');
                        this.pwd = '';
                        this.cnfPwd = '';
                    }  else {
                        this.$refs.messageModal.showModal(result.data.code);
                    }
                                 
                        
                } else {
                    this.errors ={"pwd": ['Password not match'], "cnfPwd": ['Password not match']};
                }
            }
        },
        async chgProfile() {
            let name = this.name;
            let email = this.email;
            if (name.trim() != '' && email.trim() != '') {
                const result = await axios.put(`${process.env.VUE_APP_APIURL}/admin/profile`, {
                    "name": name,
                    "email": email
                } , {
                    headers: authHeader(),

                });
                if (result.status === 200) {
                    if (result.data.code === 'success') {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                        let getStorage = JSON.parse(localStorage.getItem('user'));
                        getStorage.name = this.name;
                        localStorage.setItem('user', JSON.stringify(getStorage));
                        // console.log( this.$refs.messageModal.show);
                        // location.reload();
                    } else if (result.data.code === 'invalid_field') {
                        this.$refs.messageModal.showModal('Invalid Field ' + Object.keys(result.data.errors));
                    }
                } else {
                    this.$refs.messageModal.showModal(result.message);
                }
                
            }
        },
        refreshPage() {
            location.reload();
        }
    },
    async created() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/admin/verify`, {
            headers: authHeader(),
        });
        // console.log(result);
        if (result.data['code'] === 'success') {
            this.name = result.data.data.profile.name;
            this.email = result.data.data.profile.email;

        } else {
            alert(result.message);
            this.$router.push('/');
        }
    },
   
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" closed=""/>
        <MessageModal ref="messageModal" @CloseBtn="refreshPage()"/>
        <div class="card card-form p-3">
            <div class="row no-gutters p-3">
                <div class="col-lg-4 card-body">
                    <p class="mb-0"><strong class="headings-color">Basic Information</strong></p>
                    <p class="text-muted">Edit your account details and settings.</p>
                </div>
                <div class="col-lg-8 card-form__body card-body">
                    <form class="form-horizontal" method="POST" id="profile-basic-form">
                        <div class="form-group">
                            <InputGroup type="text" id="name" displayName="Name" v-model="name" :error="errors.name" />
                        </div>
                        <div class="form-group">
                            <InputGroup type="email" id="email" displayName="Email" v-model="email" :error="errors.email" />
                        </div>
                        <div class="text-left mt-3">
                            <SaveButton @click="chgProfile" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card card-form p-3">
            <div class="row no-gutters p-3">
                <div class="col-lg-4 card-body">
                    <p class="mb-0"><strong class="headings-color">Password</strong></p>
                    <p class="text-muted">Change Your Password</p>
                </div>
                <div class="col-lg-8 card-form__body card-body">
                    <form class="form-horizontal" method="POST" id="profile-email-form">
                        <div class="form-group">
                            <InputGroup type="password" id="password" displayName="New Password" v-model="pwd" :error="errors.pwd" />
                        </div>
                        <div class="form-group">
                            <InputGroup type="password" id="confirm_password" displayName="Confirm Password" v-model="cnfPwd" :error="errors.cnfPwd" />
                        </div>
                        <div class="text-left mt-3">
                            <SaveButton @click="chgPassword"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>